import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
    Button,
    IconButton,
    Dialog,
    DialogContent,
    DialogActions,
    TextField,
    Typography,
    Alert,
    DialogTitle,
    Chip, Avatar
} from '@mui/material';
import {
    Rocket,
    Book,
    Trophy,
    Trash2,
    Plus,
    Edit3,
    Award,
    Star,
    Clock,
    CheckCircle2
} from 'lucide-react';
import {
    fetchUserProfile,
    fetchUserAchievements,
    fetchUserBadges,
    fetchActiveSandboxes,
    fetchEnrollments,
    startCamundaProcess,
    updateUserProfile,
    fetchInstanceByProcessId,
    updateInstanceArchitecture,
    createSandboxComponent,
    fetchComponentTypes,
    deployTemplate
} from '../services/apiService';
import CreateSandboxDialog from './sandbox/CreateSandboxDialog';
import GenerationLoader from './GenerationLoader';

const Dashboard = ({ token, userInfo }) => {
    // State для данных пользователя
    const [profile, setProfile] = useState(null);
    const [achievements, setAchievements] = useState([]);
    const [badges, setBadges] = useState([]);
    const [enrollments, setEnrollments] = useState([]);
    const [componentTypes, setComponentTypes] = useState([]);

    // State для песочниц
    const [mySandboxes, setMySandboxes] = useState([]);
    const [accessibleSandboxes, setAccessibleSandboxes] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    // State для диалогов
    const [isEditing, setIsEditing] = useState(false);
    const [createDialogOpen, setCreateDialogOpen] = useState(false);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [sandboxToDelete, setSandboxToDelete] = useState(null);

    // State для создания/редактирования
    const [formData, setFormData] = useState({
        name: '',
        last_name: '',
        bio: '',
    });
    const [error, setError] = useState(null);

    // State для процесса генерации
    const [processId, setProcessId] = useState(null);
    const [sandboxId, setSandboxId] = useState(null);
    const [generationStarted, setGenerationStarted] = useState(false);

    // Загрузка данных при монтировании
    useEffect(() => {
        const loadData = async () => {
            if (!token || !userInfo) return;

            setIsLoading(true);
            setError(null);

            try {
                const [
                    profileData,
                    achievementsData,
                    badgesData,
                    mySandboxes,
                    accessibleSandboxes,
                    enrollmentsData,
                    componentTypesData
                ] = await Promise.all([
                    fetchUserProfile(token, userInfo.id),
                    fetchUserAchievements(token),
                    fetchUserBadges(token),
                    fetchActiveSandboxes(token, userInfo.id, 'owner'),
                    fetchActiveSandboxes(token, userInfo.id, 'coworker'),
                    fetchEnrollments(token),
                    fetchComponentTypes(token)
                ]);

                setProfile(profileData);
                setFormData({
                    name: profileData.name || '',
                    last_name: profileData.last_name || '',
                    bio: profileData.bio || '',
                });
                setAchievements(achievementsData);
                setBadges(badgesData);
                setMySandboxes(mySandboxes);
                setAccessibleSandboxes(accessibleSandboxes);
                setEnrollments(enrollmentsData);
                setComponentTypes(componentTypesData);
            } catch (err) {
                setError('Ошибка загрузки данных');
                console.error('Error loading dashboard data:', err);
            } finally {
                setIsLoading(false);
            }
        };

        loadData();
    }, [token, userInfo]);


    // Обработчики для песочниц
    const handleCreateSandbox = async (data) => {
        try {
            setError(null);

            switch (data.type) {
                case 0: // По описанию
                    const response = await startCamundaProcess(
                        token,
                        data.prompt,
                        userInfo.id,
                        'base',
                        null
                    );
                    setProcessId(response.process_id);
                    setSandboxId(response.sandbox_id);
                    break;

                case 1: // Вручную
                    // Запускаем процесс в ручном режиме
                    const manualResponse = await startCamundaProcess(
                        token,
                        'manual',
                        userInfo.id,
                        'manual',
                        null
                    );

                    // Создаем архитектуру из выбранных компонентов
                    const architecture = {
                        backend_components: data.components.map(component => ({
                            name: component.name,
                            description: component.prompt,
                            type: componentTypes.find(t => t.id === component.type)?.name || '',
                            subtype: component.subtype
                        }))
                    };

                    // Сохраняем архитектуру в instance
                    const instance = await fetchInstanceByProcessId(token, manualResponse.process_id);
                    await updateInstanceArchitecture(token, instance.id, architecture);

                    // Создаем компоненты
                    for (const component of data.components) {
                        await createSandboxComponent(token, {
                            sandbox: manualResponse.sandbox_id,
                            name: component.systemName,
                            host: `https://sandbox.dvakota.com/${manualResponse.namespace}/${component.systemName}`,
                            port: 0,
                            type_name_input: componentTypes.find(t => t.id === component.type)?.name,
                            status: 'creating',
                            configuration: {
                                name: component.systemName,
                                description: component.prompt,
                                type: componentTypes.find(t => t.id === component.type)?.name,
                                subtype: component.subtype
                            },
                            description: component.prompt
                        });
                    }

                    setProcessId(manualResponse.process_id);
                    setSandboxId(manualResponse.sandbox_id);
                    break;

                case 2: // Из шаблона
                    const templateResponse = await deployTemplate(
                             token,
                             data.templateId
                    );
                    setProcessId(templateResponse.process_id);
                    setSandboxId(templateResponse.sandbox_id);
                    break;

                case 3: // Мне повезёт
                    const luckyResponse = await startCamundaProcess(
                        token,
                        'Случайная, но очень интересная для изучения система',
                        userInfo.id,
                        'base',
                        null
                    );
                    setProcessId(luckyResponse.process_id);
                    setSandboxId(luckyResponse.sandbox_id);
                    break;

                default:
                    setError('Неизвестный тип создания песочницы');
                    return;
            }

            setGenerationStarted(true);
            setCreateDialogOpen(false);
        } catch (err) {
            setError('Ошибка при создании песочницы');
            console.error('Error creating sandbox:', err);
        }
    };


    const handleDeleteClick = (sandbox) => {
        setSandboxToDelete(sandbox);
        setDeleteDialogOpen(true);
    };

    const handleDeleteConfirm = async () => {
        if (!sandboxToDelete) return;

        try {
            await startCamundaProcess(
                token,
                'delete',
                userInfo.id,
                'delete',
                sandboxToDelete.id
            );

            // Обновляем списки песочниц
            const updatedMySandboxes = mySandboxes.filter(s => s.id !== sandboxToDelete.id);
            const updatedAccessibleSandboxes = accessibleSandboxes.filter(s => s.id !== sandboxToDelete.id && s.for_assignment === false);

            setMySandboxes(updatedMySandboxes);
            setAccessibleSandboxes(updatedAccessibleSandboxes);
            setDeleteDialogOpen(false);
            setSandboxToDelete(null);
        } catch (err) {
            setError('Ошибка при удалении песочницы');
            console.error('Error deleting sandbox:', err);
        }
    };

    // Обработчики для профиля
    const handleSaveProfile = async () => {
        try {
            const updatedProfile = await updateUserProfile(token, profile.id, formData);
            setProfile(updatedProfile);
            setIsEditing(false);
        } catch (err) {
            setError('Ошибка при сохранении профиля');
            console.error('Error saving profile:', err);
        }
    };

    // Компонент для отображения курса
    const CourseCard = ({ enrollment }) => {
        // Проверяем, есть ли данные курса
        if (!enrollment?.course) {
            return null;
        }

        return (
            <div className="bg-white rounded-lg shadow-sm p-4 border border-gray-100 hover:border-gray-200">
                <div className="flex justify-between items-start">
                    <div>
                        <Link
                            to={`/courses/${enrollment.course.id}`}
                            className="text-lg font-medium text-blue-600 hover:text-blue-800"
                        >
                            {enrollment.course.title}
                        </Link>
                        {enrollment.course.description && (
                            <p className="text-sm text-gray-600 mt-1">
                                {enrollment.course.description}
                            </p>
                        )}
                    </div>
                    <div className="flex items-center gap-2">
                        {enrollment.progress >= 100 ? (
                            <CheckCircle2 className="w-5 h-5 text-green-500" />
                        ) : (
                            <Clock className="w-5 h-5 text-blue-500" />
                        )}
                    </div>
                </div>
                <div className="mt-4">
                    <div className="w-full bg-gray-200 rounded-full h-2">
                        <div
                            className="bg-blue-500 h-2 rounded-full transition-all duration-300"
                            style={{ width: `${enrollment.progress || 0}%` }}
                        />
                    </div>
                    <span className="text-sm text-gray-600 mt-1">
          Прогресс: {Math.round(enrollment.progress || 0)}%
        </span>
                </div>
            </div>
        );
    };

    // Компонент для отображения сетки песочниц
    const SandboxGrid = ({ sandboxes, isOwner = true }) => (
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
            {sandboxes.map(sandbox => (
                <div key={sandbox.id} className="bg-white rounded-lg shadow-sm p-4 border border-gray-100 hover:border-gray-200">
                    <div className="flex justify-between items-start">
                        <Link
                            to={`/sandbox/${sandbox.id}`}
                            className="text-lg font-medium text-blue-600 hover:text-blue-800"
                        >
                            {sandbox.name}
                        </Link>
                        {isOwner && (
                            <IconButton
                                onClick={() => handleDeleteClick(sandbox)}
                                className="text-red-500 hover:text-red-700"
                                size="small"
                            >
                                <Trash2 className="w-4 h-4" />
                            </IconButton>
                        )}
                    </div>
                </div>
            ))}
            {isOwner && (
                <button
                    onClick={() => setCreateDialogOpen(true)}
                    className="bg-gray-50 rounded-lg border-2 border-dashed border-gray-300 p-4 hover:border-blue-500 hover:bg-gray-100 transition-colors flex flex-col items-center justify-center gap-2"
                >
                    <Plus className="w-8 h-8 text-gray-400" />
                    <span className="text-gray-600">Создать песочницу</span>
                </button>
            )}
        </div>
    );

    if (isLoading) {
        return (
            <div className="min-h-screen flex items-center justify-center">
                <div className="animate-pulse text-gray-500">Загрузка...</div>
            </div>
        );
    }

    return (
        <div className="min-h-screen bg-gray-50 py-8">
            <div className="max-w-7xl mx-auto px-4 space-y-8">
                {error && (
                    <Alert severity="error" onClose={() => setError(null)}>
                        {error}
                    </Alert>
                )}

                {/* Профиль */}
                <div className="bg-white rounded-xl shadow-sm p-6 mb-8">
                    <div className="flex items-start justify-between">
                        <div className="flex gap-6">
                            <Avatar className="bg-[#FF6F00] mr-2">
                                {profile?.avatar
                                    ? profile.avatar
                                    : userInfo.username.charAt(0).toUpperCase()}
                            </Avatar>
                            <div>
                                <div className="flex items-center gap-3">
                                    <h1 className="text-2xl font-bold">
                                        {profile?.name
                                            ? `${profile.name} ${profile.last_name}`
                                            : userInfo.username}
                                    </h1>
                                    <IconButton onClick={() => setIsEditing(true)} size="small">
                                        <Edit3 className="w-4 h-4" />
                                    </IconButton>
                                </div>
                                <p className="text-gray-600 mt-1">{profile?.bio || 'Добавьте информацию о себе'}</p>
                                <div className="flex gap-4 mt-4">
                                    <div className="flex items-center gap-2">
                                        <Trophy className="w-5 h-5 text-yellow-500" />
                                        <span>Уровень {profile?.level || 1}</span>
                                    </div>
                                    <div className="flex items-center gap-2">
                                        <Star className="w-5 h-5 text-purple-500" />
                                        <span>{profile?.points || 0} баллов</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {achievements?.length > 0 && (
                            <div className="flex flex-wrap gap-2 mt-4">
                                <Typography variant="subtitle2" className="w-full text-gray-600">
                                    Достижения:
                                </Typography>
                                {achievements.map(achievement => (
                                    <Chip
                                        key={achievement.id}
                                        label={achievement.achievement.name}
                                        icon={<Trophy className="w-4 h-4" />}
                                        size="small"
                                        className="bg-yellow-50"
                                    />
                                ))}
                            </div>
                        )}

                        {badges?.length > 0 && (
                            <div className="flex gap-2">
                                {badges.map(badge => (
                                    <Award
                                        key={badge.id}
                                        className="w-6 h-6 text-blue-500"
                                        title={badge.name}
                                    />
                                ))}
                            </div>
                        )}
                    </div>
                </div>

                <div className="space-y-8">
                    {/* Основные секции */}
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                        {/* Секция песочниц */}
                        <section className="bg-white rounded-xl shadow-sm p-6">
                            <div className="flex items-center gap-2 mb-6">
                                <Rocket className="w-6 h-6 text-blue-500" />
                                <h2 className="text-xl font-semibold">Мои песочницы</h2>
                            </div>
                            <SandboxGrid sandboxes={mySandboxes} />
                        </section>

                        {/* Секция курсов */}
                        <section className="bg-white rounded-xl shadow-sm p-6">
                            <div className="flex items-center gap-2 mb-6">
                                <Book className="w-6 h-6 text-green-500" />
                                <h2 className="text-xl font-semibold">Мои курсы</h2>
                            </div>
                            <div className="space-y-4">
                                {enrollments.length > 0 ? (
                                    enrollments
                                        .filter(enrollment => enrollment.is_active)
                                        .map(enrollment => (
                                            <CourseCard key={enrollment.id} enrollment={enrollment} />
                                        ))
                                ) : (
                                    <p className="text-gray-500">У вас пока нет активных курсов</p>
                                )}
                            </div>
                        </section>
                    </div>

                    {/* Доступные песочницы */}
                    {accessibleSandboxes.length > 0 && (
                        <section className="bg-white rounded-xl shadow-sm p-6">
                            <div className="flex items-center gap-2 mb-6">
                                <h2 className="text-xl font-semibold">Доступные песочницы</h2>
                            </div>
                            <SandboxGrid sandboxes={accessibleSandboxes} isOwner={false} />
                        </section>
                    )}
                </div>


                {/* Диалог редактирования профиля */}
                <Dialog open={isEditing} onClose={() => setIsEditing(false)} maxWidth="sm" fullWidth>
                    <DialogTitle>Редактировать профиль</DialogTitle>
                    <DialogContent>
                        <div className="space-y-4 mt-2">
                            <TextField
                                fullWidth
                                label="Имя"
                                value={formData.name}
                                onChange={(e) => setFormData({...formData, name: e.target.value})}
                            />
                            <TextField
                                fullWidth
                                label="Фамилия"
                                value={formData.last_name}
                                onChange={(e) => setFormData({...formData, last_name: e.target.value})}
                            />
                            <TextField
                                fullWidth
                                multiline
                                rows={4}
                                label="О себе"
                                value={formData.bio}
                                onChange={(e) => setFormData({...formData, bio: e.target.value})}
                            />
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setIsEditing(false)}>Отмена</Button>
                        <Button variant="contained" onClick={handleSaveProfile}>Сохранить</Button>
                    </DialogActions>
                </Dialog>

                {/* Диалог создания песочницы */}
                <CreateSandboxDialog
                    open={createDialogOpen}
                    onClose={() => setCreateDialogOpen(false)}
                    onSubmit={handleCreateSandbox}
                    token={token}
                    error={error}
                    setError={setError}
                    componentTypes={componentTypes}
                />

                {/* Диалог подтверждения удаления */}
                <Dialog
                    open={deleteDialogOpen}
                    onClose={() => setDeleteDialogOpen(false)}
                    maxWidth="xs"
                    fullWidth
                >
                    <DialogTitle className="text-red-600">
                        Удаление песочницы
                    </DialogTitle>
                    <DialogContent>
                        <Typography>
                            Вы уверены, что хотите удалить песочницу "{sandboxToDelete?.name}"?
                            Это действие нельзя будет отменить.
                        </Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={() => setDeleteDialogOpen(false)}
                            className="text-gray-600"
                        >
                            Отмена
                        </Button>
                        <Button
                            variant="contained"
                            color="error"
                            onClick={handleDeleteConfirm}
                        >
                            Удалить
                        </Button>
                    </DialogActions>
                </Dialog>

                {/* Если началась генерация, открываем лоадер */}
                {generationStarted && processId && sandboxId && (
                    <GenerationLoader
                        token={token}
                        processId={processId}
                        sandboxId={sandboxId}
                    />
                )}
            </div>
        </div>
    );
};

export default Dashboard;
import React, { useState, useEffect, useContext, useCallback } from 'react';
import { useParams, Link } from 'react-router-dom';
import {
    Container,
    Grid,
    Typography,
    Box,
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    TextField,
    Tab,
    Tabs,
    Card,
    CardContent,
    List,
    ListItem,
    ListItemText,
    Avatar,
    Chip,
    Divider,
    Rating,
    CircularProgress,
    LinearProgress
} from '@mui/material';
import { Person, Assignment, Lock, LockOpen, Star, CheckCircle } from '@mui/icons-material';
import { AuthContext } from '../App';
import {
    fetchCourseDetails,
    fetchEnrollments,
    enrollInCourse,
    fetchUserProfile,
    fetchCourseReviews,
    submitCourseReview,
    unenrollFromCourse,
    fetchCourseComments,
    createCourseComment,
    fetchCourseRatings
} from '../services/apiService';
import AuthDialog from '../components/AuthDialog';
import { formatDistance } from 'date-fns';

const CourseDetail = ( userInfo ) => {
    const {courseId} = useParams();
    const [course, setCourse] = useState(null);
    const [activeTab, setActiveTab] = useState(0);
    const {isAuthenticated, token, updateToken} = useContext(AuthContext);
    const [isEnrolled, setIsEnrolled] = useState(false);
    const [enrollmentDetails, setEnrollmentDetails] = useState(null);
    const [mentors, setMentors] = useState([]);
    const [showFullDescription, setShowFullDescription] = useState(false);
    const [showAuthDialog, setShowAuthDialog] = useState(false);
    const [showReviewForm, setShowReviewForm] = useState(false);
    const [userRating] = useState(null);
    const [averageRating, setAverageRating] = useState(0);
    const [reviews, setReviews] = useState([]);
    const [ratings, setRatings] = useState([]);

    const toggleDescription = () => {
        setShowFullDescription(!showFullDescription);
        setActiveTab(0);
    };

    const calculateAverageRating = (ratings) => {
        if (!ratings || ratings.length === 0) return 0;
        const sum = ratings.reduce((acc, curr) => acc + curr.rating, 0);
        return sum / ratings.length;
    };

    const [isLoading, setIsLoading] = useState({
        course: true,
        mentors: false,
        reviews: false
    });

    const checkEnrollmentStatus = useCallback(async () => {
        if (!isAuthenticated || !token) {
            setIsEnrolled(false);
            setEnrollmentDetails(null);
            return;
        }

        try {
            const enrollments = await fetchEnrollments(token);

            const courseEnrollment = enrollments.find(enrollment =>
                enrollment.course.id === courseId &&
                enrollment.is_active
            );


            setIsEnrolled(Boolean(courseEnrollment));
            setEnrollmentDetails(courseEnrollment || null);
        } catch (error) {
            console.error("Error checking enrollment status:", error);
            setIsEnrolled(false);
            setEnrollmentDetails(null);
        }
    }, [courseId, isAuthenticated, token]);

    const loadCourseData = useCallback(async () => {
        setIsLoading(prev => ({ ...prev, course: true }));
        try {
            const [courseDetails, enrollmentStatus] = await Promise.all([
                fetchCourseDetails(courseId),
                isAuthenticated ? fetchEnrollments(token) : Promise.resolve([])
            ]);

            setCourse(courseDetails);

            if (isAuthenticated) {
                const courseEnrollment = enrollmentStatus.find(
                    e => e.course.id === parseInt(courseId) && e.is_active
                );
                setIsEnrolled(!!courseEnrollment);
                setEnrollmentDetails(courseEnrollment || null);
            }

            // Загружаем отзывы и рейтинги
            const [reviewsData, ratingsData] = await Promise.all([
                fetchCourseReviews(courseId),
                fetchCourseRatings(courseId)
            ]);

            setReviews(reviewsData);
            setRatings(ratingsData);
            setAverageRating(calculateAverageRating(ratingsData));
        } catch (error) {
            console.error("Error loading course data:", error);
        } finally {
            setIsLoading(prev => ({ ...prev, course: false }));
        }
    }, [courseId, isAuthenticated, token]);

    useEffect(() => {
        loadCourseData();
    }, [loadCourseData]);

    useEffect(() => {
        if (ratings.length > 0) {
            setAverageRating(calculateAverageRating(ratings));
        }
    }, [ratings]);

    useEffect(() => {
        const loadMentors = async () => {
            if (!course?.mentors?.length || !token) return;

            setIsLoading(prev => ({...prev, mentors: true}));
            try {
                const mentorPromises = course.mentors.map(mentorId =>
                    fetchUserProfile(token, mentorId).catch(error => {
                        console.error(`Error loading mentor ${mentorId}:`, error);
                        return null;
                    })
                );

                const mentorProfiles = await Promise.all(mentorPromises);
                const validMentors = mentorProfiles.filter(Boolean);
                setMentors(validMentors);
            } catch (error) {
                console.error("Error loading mentors:", error);
            } finally {
                setIsLoading(prev => ({...prev, mentors: false}));
            }
        };

        loadMentors();
    }, [course?.mentors, token, setIsLoading]);


    const handleTabChange = (event, newValue) => {
        setActiveTab(newValue);
    };

    const handleEnroll = async () => {
        if (!isAuthenticated) {
            setShowAuthDialog(true);
            return;
        }

        try {
            await enrollInCourse(token, courseId);
            setIsEnrolled(true);
            await checkEnrollmentStatus();
        } catch (error) {
            console.error("Error enrolling in course:", error);
        }
    };

    const handleUnenroll = async () => {
        try {
            await unenrollFromCourse(token, courseId);
            setIsEnrolled(false);
            await checkEnrollmentStatus();
        } catch (error) {
            console.error("Error unenrolling from course:", error);
        }
    };

    const renderEnrollmentButton = () => {
        if (!course) return null;

        return (
            <Button
                variant="contained"
                color={isEnrolled ? "secondary" : "primary"}
                fullWidth
                size="large"
                className="mb-6 mt-4"
                onClick={isEnrolled ? handleUnenroll : handleEnroll}
                disabled={!isAuthenticated}
            >
                {!isAuthenticated
                    ? "Войдите, чтобы записаться"
                    : isEnrolled
                        ? "Отписаться от курса"
                        : "Записаться на курс"}
            </Button>
        );
    };

    const renderContent = () => {
        if (isLoading.course) {
            return (
                <Container maxWidth="lg" className="py-8">
                    <Box className="flex justify-center items-center min-h-[400px]">
                        <CircularProgress/>
                    </Box>
                </Container>
            );
        }

        if (!course) {
            return (
                <Container maxWidth="lg" className="py-8">
                    <Box className="flex justify-center items-center">
                        <Typography>Курс не найден</Typography>
                    </Box>
                </Container>
            );
        }


        const MentorsList = ({mentors, loading}) => (
            <Box className="space-y-6">
                {loading ? (
                    <Box display="flex" justifyContent="center">
                        <CircularProgress/>
                    </Box>
                ) : mentors.length > 0 ? (
                    mentors.map((mentor) => (
                        <Card key={mentor.id} className="hover:shadow-lg transition-shadow">
                            <CardContent className="flex items-start space-x-4">
                                <Avatar
                                    src={mentor.avatar}
                                    className="w-16 h-16"
                                    alt={mentor.name || mentor.user?.username || 'Mentor'}
                                />
                                <Box className="flex-1">
                                    <Typography variant="h6" className="font-medium">
                                        {mentor.name || mentor.user?.username || 'Mentor'}
                                    </Typography>
                                    {mentor.last_name && (
                                        <Typography variant="h6" className="font-medium">
                                            {mentor.last_name}
                                        </Typography>
                                    )}
                                    {mentor.bio && (
                                        <Typography variant="body2" className="mt-2 text-gray-600">
                                            {mentor.bio}
                                        </Typography>
                                    )}
                                </Box>
                            </CardContent>
                        </Card>
                    ))
                ) : (
                    <Typography>Нет доступных менторов</Typography>
                )}
            </Box>
        );

        const ReviewForm = ({onSubmit, initialRating = 0, initialContent = ''}) => {
            const [rating, setRating] = useState(initialRating);
            const [content, setContent] = useState(initialContent);

            const handleSubmit = (e) => {
                e.preventDefault();
                onSubmit({rating, content});
                setRating(0);
                setContent('');
            };

            return (
                <Box component="form" onSubmit={handleSubmit} className="space-y-4">
                    <Box className="flex items-center space-x-2">
                        <Typography component="legend">Ваша оценка:</Typography>
                        <Rating
                            name="rating"
                            value={rating}
                            onChange={(_, newValue) => setRating(newValue)}
                        />
                    </Box>
                    <TextField
                        fullWidth
                        multiline
                        rows={4}
                        variant="outlined"
                        placeholder="Поделитесь своими впечатлениями от курса..."
                        value={content}
                        onChange={(e) => setContent(e.target.value)}
                    />
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        disabled={!rating || !content.trim()}
                    >
                        Отправить отзыв
                    </Button>
                </Box>
            );
        };

        const ReviewsList = ({reviews, ratings}) => {
            const getUserRating = useCallback((userId) => {
                return ratings?.find(r => r.user === userId)?.rating || 0;
            }, [ratings]);

            if (!reviews?.length) {
                return (
                    <Typography align="center" color="textSecondary">
                        Пока нет отзывов
                    </Typography>
                );
            }

            return (
                <List className="space-y-4">
                    {reviews.map((review) => (
                        <ListItem key={review.id} className="flex flex-col items-start">
                            <Box className="flex items-center space-x-2 w-full">
                                <Avatar
                                    src={review.user?.avatar}
                                    alt={review.user?.username || 'User'}
                                />
                                <Box className="flex-1">
                                    <Typography variant="subtitle2" className="font-medium">
                                        {review.user?.username || 'Пользователь'}
                                    </Typography>
                                    <Rating
                                        value={getUserRating(review.user?.id)}
                                        readOnly
                                        size="small"
                                        precision={0.5}
                                    />
                                </Box>
                                <Typography variant="caption" className="text-gray-500">
                                    {formatDistance(new Date(review.created_at), new Date(), {addSuffix: true})}
                                </Typography>
                            </Box>
                            <Typography variant="body2" className="mt-2 ml-12">
                                {review.content}
                            </Typography>
                            <Divider className="w-full mt-4"/>
                        </ListItem>
                    ))}
                </List>
            );
        };

        const Discussion = ({courseId, token}) => {
            const [comments, setComments] = useState([]);
            const [newComment, setNewComment] = useState('');

            const fetchComments = useCallback(async () => {
                try {
                    const response = await fetchCourseComments(token, courseId);
                    const data = await response.json();
                    setComments(data);
                } catch (error) {
                    console.error('Error fetching comments:', error);
                }
            }, [courseId, token]);

            const handleSubmitComment = async (e) => {
                e.preventDefault();
                try {
                    await createCourseComment(token, courseId, newComment);
                    setNewComment('');
                    await fetchComments();
                } catch (error) {
                    console.error('Error posting comment:', error);
                }
            };

            useEffect(() => {
                fetchComments();
            }, [courseId, fetchComments]);

            return (
                <Box className="space-y-6">
                    <Box component="form" onSubmit={handleSubmitComment} className="space-y-4">
                        <TextField
                            fullWidth
                            multiline
                            rows={3}
                            variant="outlined"
                            placeholder="Задай вопрос или начни обсуждение..."
                            value={newComment}
                            onChange={(e) => setNewComment(e.target.value)}
                        />
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            disabled={!newComment.trim()}
                        >
                            Отправить комментарий
                        </Button>
                    </Box>
                    <List className="space-y-4">
                        {comments.map((comment) => (
                            <Card key={comment.id} className="hover:shadow-md transition-shadow">
                                <CardContent>
                                    <Box className="flex items-center space-x-2">
                                        <Avatar src={comment.user.avatar} alt={comment.user.username}/>
                                        <Box className="flex-1">
                                            <Typography variant="subtitle2" className="font-medium">
                                                {comment.user.username}
                                            </Typography>
                                            <Typography variant="caption" className="text-gray-500">
                                                {formatDistance(new Date(comment.created_at), new Date(), {addSuffix: true})}
                                            </Typography>
                                        </Box>
                                    </Box>
                                    <Typography variant="body2" className="mt-2 ml-12">
                                        {comment.content}
                                    </Typography>
                                </CardContent>
                            </Card>
                        ))}
                    </List>
                </Box>
            );
        };


        const totalLessons = course?.modules?.reduce((acc, module) => acc + module.lessons.length, 0) || 0;

        return (
            <Container maxWidth="lg" className="py-8">
                <Grid container spacing={6}>
                    <Grid item xs={12} md={8}>
                        <Typography variant="h4" component="h1" className="mb-6 font-bold text-primary">
                            {course?.title}
                        </Typography>
                        <Typography variant="body1" className="py-4">
                            {showFullDescription ? course?.description : `${course?.description?.slice(0, 150)}...`}
                            <Button onClick={toggleDescription} color="primary">
                                {showFullDescription ? 'Свернуть' : 'Подробнее'}
                            </Button>
                        </Typography>
                        <Box className="mb-6 flex flex-wrap gap-2">
                            <Chip icon={<Person/>}
                                  label={`${course?.mentors?.length || 0} mentor${course?.mentors?.length !== 1 ? 's' : ''}`}/>
                            <Chip icon={<Assignment/>} label={`${totalLessons} уроков`}/>
                            <Chip
                                icon={<Star/>}
                                label={
                                    <Box display="flex" alignItems="center">
                                        <Rating
                                            value={averageRating}
                                            readOnly
                                            size="small"
                                            precision={0.5}
                                        />
                                        <Typography variant="body2" ml={1}>
                                            ({ratings.length} оценок)
                                        </Typography>
                                    </Box>
                                }
                            />
                        </Box>
                        <Divider className="mb-6"/>
                        <Tabs value={activeTab} onChange={handleTabChange} className="mb-6">
                            <Tab label="О курсе"/>
                            <Tab label="Программа курса"/>
                            <Tab label="Преподаватели"/>
                            <Tab label="Отзывы"/>
                            <Tab label="Обсуждения"/>
                        </Tabs>
                        <Box className="p-6 bg-gray-50 rounded-lg">
                            {activeTab === 0 && (
                                <Typography variant="body1">{course.description}</Typography>
                            )}
                            {activeTab === 1 && (
                                <List>
                                    {course?.modules?.map((module) => (
                                        <React.Fragment key={module.id}>
                                            <ListItem>
                                                <ListItemText
                                                    primary={<Typography variant="h6">{module.title}</Typography>}
                                                    secondary={`${module.lessons.length} уроков`}
                                                />
                                            </ListItem>
                                            <List component="div" disablePadding>
                                                {module.lessons.map((lesson) => (
                                                    <ListItem
                                                        key={lesson.id}
                                                        sx={{pl: 4}}
                                                        component={Link}
                                                        to={`/courses/${courseId}/lessons/${lesson.id}`}
                                                        disabled={lesson.is_locked && !isEnrolled}
                                                    >
                                                        <ListItemText
                                                            primary={
                                                                <Box display="flex" alignItems="center">
                                                                    <Typography>{lesson.title}</Typography>
                                                                    {lesson.is_locked ? (
                                                                        <Lock fontSize="small" color="action" sx={{ml: 1}}/>
                                                                    ) : lesson.progress?.is_completed ? (
                                                                        <CheckCircle fontSize="small" color="success" sx={{ml: 1}}/>
                                                                    ) : (
                                                                        <LockOpen fontSize="small" color="action" sx={{ml: 1}}/>
                                                                    )}
                                                                </Box>
                                                            }
                                                            secondary={
                                                                <Box>
                                                                    {lesson.estimated_time &&
                                                                        `Примерное время: ${lesson.estimated_time} мин.`
                                                                    }
                                                                    {isEnrolled && lesson.progress && (
                                                                        <Box mt={1}>
                                                                            <Typography variant="caption">
                                                                                Выполнено заданий: {lesson.completed_assignments_count} из {lesson.assignments_count}
                                                                            </Typography>
                                                                            <LinearProgress
                                                                                variant="determinate"
                                                                                value={lesson.progress.progress_percent}
                                                                                sx={{mt: 0.5}}
                                                                            />
                                                                        </Box>
                                                                    )}
                                                                </Box>
                                                            }
                                                        />
                                                    </ListItem>
                                                ))}
                                            </List>
                                        </React.Fragment>
                                    ))}
                                </List>
                            )}
                            {activeTab === 2 && (
                                <MentorsList
                                    mentors={mentors}
                                    loading={isLoading.mentors}
                                />
                            )}
                            {activeTab === 3 && (
                                <Box>
                                    {isLoading.reviews ? (
                                        <Box display="flex" justifyContent="center" py={4}>
                                            <CircularProgress/>
                                        </Box>
                                    ) : (
                                        <>
                                            {isAuthenticated && (
                                                <Button
                                                    variant="outlined"
                                                    color="primary"
                                                    onClick={() => setShowReviewForm(true)}
                                                    className="mb-4"
                                                >
                                                    Написать отзыв
                                                </Button>
                                            )}
                                            <ReviewsList reviews={reviews} ratings={ratings}/>
                                        </>
                                    )}
                                    <Dialog open={showReviewForm} onClose={() => setShowReviewForm(false)}>
                                        <DialogTitle>Написать отзыв</DialogTitle>
                                        <DialogContent>
                                            <ReviewForm
                                                onSubmit={async (reviewData) => {
                                                    await submitCourseReview(token, courseId, reviewData);
                                                    setShowReviewForm(false);
                                                    // Обновляем список отзывов
                                                    const updatedReviews = await fetchCourseReviews(courseId);
                                                    const updatedRatings = await fetchCourseRatings(courseId);
                                                    setReviews(updatedReviews);
                                                    setRatings(updatedRatings);
                                                    setAverageRating(calculateAverageRating(updatedRatings));
                                                }}
                                                initialRating={userRating?.rating}
                                            />
                                        </DialogContent>
                                    </Dialog>
                                </Box>
                            )}
                            {activeTab === 4 && (
                                <Discussion
                                    courseId={courseId}
                                    token={token}
                                    currentUser={userInfo.id}
                                />
                            )}

                        </Box>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Card className="sticky top-24">
                            <CardContent>
                                <Typography variant="h6" className="py-2">
                                    {course.is_free ? "Бесплатно" : course.price ? `${course.price} руб.` : "Бесплатно"}
                                </Typography>
                                {renderEnrollmentButton()}
                                {isEnrolled && enrollmentDetails && (
                                    <Box>
                                        <Typography variant="body2">
                                            Общий прогресс: {Math.round(enrollmentDetails.progress)}%
                                        </Typography>
                                        <Typography variant="body2">
                                            Выполнено уроков: {enrollmentDetails.completed_lessons_count} из {enrollmentDetails.total_lessons_count}
                                        </Typography>
                                        {/* Можно добавить progress bar как в Dashboard */}
                                        <div className="w-full bg-gray-200 rounded-full h-2 mt-2">
                                            <div
                                                className="bg-blue-500 h-2 rounded-full transition-all duration-300"
                                                style={{ width: `${enrollmentDetails.progress}%` }}
                                            />
                                        </div>
                                    </Box>
                                )}
                                <Typography variant="body1" className="py-2 font-semibold">
                                    Этот курс включает:
                                </Typography>
                                <List dense>
                                    <ListItem>
                                        <ListItemText primary={`${totalLessons} уроков`}/>
                                    </ListItem>
                                    {course.has_paid_features && (
                                        <ListItem>
                                            <ListItemText primary="Платные функции"/>
                                        </ListItem>
                                    )}
                                </List>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
                <AuthDialog
                    open={showAuthDialog}
                    onClose={() => setShowAuthDialog(false)}
                    onSuccess={(token) => {
                        updateToken(token);
                        setShowAuthDialog(false);
                        handleEnroll();
                    }}
                    returnUrl={`/courses/${courseId}`}
                />
            </Container>
        );
    };
    return renderContent();
};

export default CourseDetail;